import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './menuItem.module.scss';

interface IProps {
  isActive: boolean;
  isExpanded: boolean;
  title?: string;
  linkTo: string;
  iconName: string;
  iconColor?: string;
  iconSize?: string;
  setReloadBadge: React.Dispatch<React.SetStateAction<boolean>>;
  reloadBadge: boolean;
  badge?: JSX.Element;
}

const MenuItem: React.FC<IProps> = (props) => {
  const [itemActive, setItemActive] = useState<string>('');

  useEffect(() => {
    setItemActive(styles.isActive);
  }, [props.isActive]);

  const computedStyles = getComputedStyle(document.documentElement);
  const notActiveIconColor = computedStyles.getPropertyValue('--tia-menu-item-color');
  const activeIconColor = computedStyles.getPropertyValue('--tia-menu-item-active-color');
  const iconColor = props.isActive ? activeIconColor : notActiveIconColor;
  if (props.isExpanded) {
    return (
      <NavLink
        activeClassName={itemActive}
        className={`${styles.menuItemContainer}`}
        to={props.linkTo}
        onClick={() => {
          props.setReloadBadge(!props.reloadBadge);
        }}
      >
        <div className={styles.menuIcon}>
          <tia-icon name={props.iconName} color={iconColor} size={props.iconSize} />
        </div>
        <div className={styles.menuTitle}>{props.title}</div>
        {props.badge}
      </NavLink>
    );
  }

  return (
    <NavLink activeClassName={itemActive} className={`${styles.collapsedItemContainer}`} to={props.linkTo}>
      <div className={styles.menuIcon}>
        <tia-icon name={props.iconName} color={iconColor} size={props.iconSize} />
      </div>
    </NavLink>
  );
};

export default MenuItem;
