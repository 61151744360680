import React from 'react';
import { RouteProps, Route } from 'react-router';
import ITcpRoute from '../interfaces/ITcpRoute.interface';

interface IProps extends RouteProps {
  isRoot?: boolean;
  showCart?: boolean;
}

export interface ITcpRouteProps {
  routeInfo: () => ITcpRoute;
}

let currentRoute = {
  isRoot: false,
  pathname: '/',
  showCart: false,
};

const getCurrentRoute = (): typeof currentRoute => {
  return currentRoute;
};

class TcpRoute extends React.Component<IProps> {
  private setCurrentRoute(): void {
    const { isRoot, showCart, location } = this.props;

    currentRoute = {
      isRoot: Boolean(isRoot),
      showCart: Boolean(showCart),
      pathname: location ? location.pathname : '/',
    };
  }

  public render(): JSX.Element {
    this.setCurrentRoute();

    return <Route {...this.props} />;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const withTcpRouter = <P, S>(Component: React.ComponentClass<P, S> | React.FunctionComponent<P>) => {
  return class TcpRouteClass extends React.Component<P & ITcpRouteProps, S> {
    public static defaultProps: ITcpRouteProps | P = {
      routeInfo: getCurrentRoute,
    };

    public render(): JSX.Element {
      return <Component {...this.props} />;
    }
  };
};

export default TcpRoute;

interface IRouteGuard extends IProps {
  identityPermissions: string[];
  acceptedIdentityPermissions: string[];
}

export const TcpRouteGuard = (props: IRouteGuard): JSX.Element | null => {
  const foundIdentity = props.identityPermissions.find(
    (identityType) => props.acceptedIdentityPermissions.indexOf(identityType) > -1
  );

  if (foundIdentity) {
    return <TcpRoute {...props} />;
  }

  return null;
};
