import React from 'react';
import styles from './footer.module.scss';
import { useTranslation } from 'react-i18next';

const Footer: React.FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();

  return <div className={styles.footerWrapper}>{t('copyrightText')}</div>;
};

export default Footer;
