import { useEffect, useState } from 'react';
import { apiConnector } from '../../../utils/apiConnector';
import { useTranslation } from 'react-i18next';
import { Layout, Modal } from 'antd';
import { Button, ButtonColor, RadioGroup } from '@tia/react-ui-library';
import React from 'react';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import './CreatePaymentDetail.scss';
import showToastMessage, { ToastTypes } from '../../../utils/showToastMessage';

interface Props {
  showPaymentModal: boolean;
  partyId: string;
  closePaymentModal: () => void;
}
interface IRadioOption {
  key: string;
  value: string;
}
declare enum RadioSizes {
  default = 'default',
  large = 'large',
}

export const CreatePaymentDetailComponent = ({ showPaymentModal, closePaymentModal, partyId }: Props) => {
  const { t } = useTranslation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [paymentMethods, setPaymentMethods] = useState<string[]>([]);
  const [showCustomWidget, setShowCustomWidget] = useState<boolean>(false);
  const fetchPaymentMethodsList = async (): Promise<void> => {
    try {
      const response = await apiConnector().account.getPaymentMethods();
      const paymentMethods = (response.data as unknown) as string[];
      setPaymentMethods(paymentMethods);
    } catch (e) {
      showToastMessage({
        message: t('createPaymentDetail.messages.couldNotFetchPaymentMethods'),
        type: ToastTypes.error,
      });
    }
  };

  useEffect(() => {
    fetchPaymentMethodsList();
  }, []);
  const handleContinue = () => {
    setShowCustomWidget(true);
  };
  const handleCancel = () => {
    setShowCustomWidget(false);
    closePaymentModal();
  };
  const handleCustomUiSubmit = async (event: Event) => {
    const customEvent: CustomEvent = event as CustomEvent;
    const formValues = customEvent.detail;
    if (!partyId) {
      showToastMessage({ message: t('createPaymentDetail.messages.errorNoPartyID'), type: ToastTypes.error });

      return;
    }
    try {
      const resData = await apiConnector().uiConfiguration.updateEntities(
        `PaymentDetails_${selectedPaymentMethod}`,
        formValues,
        { page: '', size: '', partyId }
      );
      if (resData?.data) {
        showToastMessage({
          message: t('createPaymentDetail.messages.creationEventSuccessful') || 'Successful',
          type: ToastTypes.success,
        });
        setShowCustomWidget(false);
        closePaymentModal();
      } else if (resData.error?.length) {
        resData.error.forEach((error) => {
          showToastMessage({ message: error.message, type: ToastTypes.error });
        });
      } else {
        showToastMessage({ message: t('createPaymentDetail.messages.errorUnspecified'), type: ToastTypes.error });

        return;
      }
    } catch (error) {
      showToastMessage({ message: t('createPaymentDetail.messages.errorUnspecified'), type: ToastTypes.error });
    }
  };

  const onRadioChangeHandle = (e: IRadioOption) => {
    setSelectedPaymentMethod(e.value);
  };

  const paymentMethodOptions: IRadioOption[] = paymentMethods?.length
    ? paymentMethods.map((paymentMethod) => {
        return { key: t(`createPaymentDetail.paymentMethods.${paymentMethod}`, paymentMethod), value: paymentMethod };
      })
    : [];

  const modalContent = !showCustomWidget ? (
    <RadioGroup
      onRadioChange={onRadioChangeHandle}
      options={paymentMethodOptions}
      disabled={false}
      radioSize={'default' as RadioSizes}
    ></RadioGroup>
  ) : (
    <CustomUiWidget
      configName={`PaymentDetails_${selectedPaymentMethod}`}
      partyId={partyId}
      handleSubmit={handleCustomUiSubmit}
      handleCancel={handleCancel}
    />
  );

  const modalFooter = !showCustomWidget
    ? [
        <Button color={ButtonColor.MINIMAL} key="cancel" onClick={handleCancel}>
          {t('createPaymentDetail.cancelButton').toString()}
        </Button>,
        <Button key="Continue" onClick={handleContinue} disabled={!selectedPaymentMethod}>
          {t('createPaymentDetail.continueButton').toString()}
        </Button>,
      ]
    : null;

  return (
    <Layout.Content style={{ marginTop: '10px' }}>
      <Modal
        title={showCustomWidget ? null : t('createPaymentDetail.widgetTitle').toString()}
        closable={false}
        open={showPaymentModal}
        onOk={handleContinue}
        onCancel={handleCancel}
        zIndex={3}
        footer={modalFooter}
        okText={t('createPaymentDetail.okText').toString()}
        cancelText={t('createPaymentDetail.cancelText').toString()}
      >
        {modalContent}
      </Modal>
    </Layout.Content>
  );
};
