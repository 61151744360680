/**
 * Loads a dynamic <script> once. The scriptIdentifier should always be unique per script
 **/

export const useScript = (
  scriptData: { src: string } & Partial<HTMLScriptElement>,
  scriptIdentifier: string,
  onLoadCallback?: () => void,
  onErrorCallback?: () => void
): void => {
  if (!scriptData.src) {
    throw new Error('Property "url" cannot be empty');
  }
  const scriptGenericClassName = 'dynamic-script';
  const fixedScriptIdentifier = scriptIdentifier.split('.').join('\\.');

  const loadedScripts = document.querySelectorAll<HTMLScriptElement>(
    `.${scriptGenericClassName}.${fixedScriptIdentifier}`
  );

  if (loadedScripts.length > 0) {
    // Script has already been (or is being) fetched
    return;
  }

  const dynamicScript = document.createElement('script');

  if (!scriptData.type) {
    delete scriptData.type;
  }

  dynamicScript.async = true;
  Object.assign(dynamicScript, scriptData);
  onLoadCallback && dynamicScript.addEventListener('load', onLoadCallback);
  onErrorCallback && dynamicScript.addEventListener('error', onErrorCallback);
  dynamicScript.className = `${scriptGenericClassName} ${scriptIdentifier}`;
  document.body.appendChild(dynamicScript);
};
