import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, ButtonColor } from '@tia/react-ui-library';
import { StyledCard, StyledCardActions, StyledCardContent, StyledCardHeader } from '../../styles/Card.styled';

export const InactivityModal: React.FC<{ onCallback: (logout: boolean) => void }> = ({ onCallback }) => {
  const { t } = useTranslation();

  return (
    <ModalContainer>
      <StyledCard>
        <StyledCardHeader>{t('sessionTimeout.title')}</StyledCardHeader>

        <StyledCardContent style={{ textAlign: 'center' }}>{t('sessionTimeout.message')}</StyledCardContent>

        <StyledCardActions>
          <Button color={ButtonColor.MINIMAL} onClick={(): void => onCallback(true)}>
            {t('sessionTimeout.buttonLogout')}
          </Button>
          <Button onClick={(): void => onCallback(false)}>{t('sessionTimeout.buttonContinue')}</Button>
        </StyledCardActions>
      </StyledCard>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.32);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;
