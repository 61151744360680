import { createContext } from 'react';
import { IIdentity } from '../interfaces/IIdToken.interface';
import { User } from 'oidc-client';

export const UserContext = createContext<{ [key: string]: IIdentity }>({});

/**
 * Extract user information from ID Token given a base64 string
 */
export const getUserIdentityTypes = (user: User): { [key: string]: IIdentity } => {
  if (!user) {
    return {};
  }

  const identities: any[] = user.profile['https://tiatechnology.com/identities'] || [];

  const result = identities.reduce((acc: { [key: string]: IIdentity }, identity: IIdentity) => {
    const identityType = identity.type.toLowerCase();

    if (identityType === 'corporateagent' || identityType === 'corporatefirm') {
      acc[identityType] = identity;
    }

    return acc;
  }, {});

  return result;
};

export const getUserPermissions = (user: User): string[] => {
  if (!user) {
    return [];
  }

  const profile = user.profile || [];

  return profile['https://tiatechnology.com/permissions'].split(' ');
};
