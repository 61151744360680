import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const i18nInit = (whitelistedLanguages, fallbackLanguage) => {
  const supportedLanguages = whitelistedLanguages ? whitelistedLanguages.split(',') : [];
  const fallbackLng = fallbackLanguage ? fallbackLanguage : 'tia';

  if (!supportedLanguages.includes(fallbackLng)) {
    supportedLanguages.push(fallbackLng);
  }

  const contextPath = window.corpEnv.contextPath;
  const translationsPath = contextPath ? `/${contextPath}/locales/{{lng}}/{{ns}}.json` : '/locales/{{lng}}/{{ns}}.json';

  return (
    i18n
      // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        ns: [
          'portal',
          'tia-agent-sales-flow-widget',
          'tia-documents-widget',
          'tia-policy-list-widget',
          'tia-custom-ui-widget',
        ],
        fallbackLng: fallbackLng,
        defaultNS: 'portal',
        supportedLngs: supportedLanguages,
        debug: false,

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
          loadPath: translationsPath,
        },
        detection: {
          // order and from where user language should be detected
          order: ['querystring', 'localStorage', 'sessionStorage', 'navigator'],

          // keys or params to lookup language from
          lookupQuerystring: 'lng',
          lookupLocalStorage: 'lng',
          lookupSessionStorage: 'lng',

          // cache user language on
          caches: ['localStorage'],
          excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
        },
      })
  );
};

export default i18nInit;
