interface IProps {
  identityPermissions: string[];
  acceptedIdentityPermissions: string[];
  children: JSX.Element;
}

const RouteGuard = ({ identityPermissions, acceptedIdentityPermissions, children }: IProps): JSX.Element | null => {
  const foundIdentity = identityPermissions.find(
    (identityType) => acceptedIdentityPermissions.indexOf(identityType) > -1
  );

  if (foundIdentity) {
    return children;
  }

  return null;
};

export default RouteGuard;
