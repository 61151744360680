import ReactDOM from 'react-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { InactivityModal } from './InactivityModalComponent';

type ACTIVITY_STATE = 'ACTIVE' | 'INACTIVE';

/** Hook provides ability to listen when user is inactive.
 * After specified inactivity time, modal window will be shown that user is about to be logged off
 * After no actions taken the logout process will begin
 **/
interface ISessionTimeout {
  onInactive?: (reasonOfInactive: string) => void;
  timeout?: number;
  modalTimeout?: number;
}
export const SessionTimeoutComponent: React.FC<ISessionTimeout> = (props) => {
  const { onInactive, timeout, modalTimeout } = props;

  if (!timeout) {
    // Dont trigger timeout logic if timeout is falsy value
    return null;
  }

  return <SessionController onInactive={onInactive} timeout={timeout} modalTimeout={modalTimeout} />;
};

const SessionController: React.FC<ISessionTimeout> = (props) => {
  const { onInactive, timeout, modalTimeout } = props;
  const [{ status, inactiveTimer }, setState] = useState<{
    status: ACTIVITY_STATE;
    inactiveTimer: number;
  }>({
    status: 'ACTIVE',
    inactiveTimer: timeout || 0,
  });

  const { current: modalRootElement } = useRef(document.querySelector('body') as HTMLBodyElement);

  const handleLogout = (message: string): void => onInactive && onInactive(message);

  const onInactivityCallback = (shouldLogout: boolean): void => {
    if (shouldLogout) {
      handleLogout('Logged out due to user will');

      return;
    }

    setState({ status: 'ACTIVE', inactiveTimer: timeout || 0 });
  };

  const handleOnIdle = (): void => {
    switch (status) {
      case 'ACTIVE':
        if (!modalTimeout) {
          // logout instantly if alert timeout is not set
          handleLogout('Logged out due to inactivity');

          return;
        }
        setState({ status: 'INACTIVE', inactiveTimer: modalTimeout });

        return;
      case 'INACTIVE':
        handleLogout('Logged out due to inactivity on alert');

        return;
      default:
        throw Error('Undefined state');
    }
  };

  const { reset } = useIdleTimer({
    timeout: inactiveTimer,
    onIdle: handleOnIdle,
    debounce: 100,
  });

  useEffect(() => {
    reset();
  }, [inactiveTimer, timeout]);

  switch (status) {
    case 'ACTIVE':
      return null;
    case 'INACTIVE':
      return ReactDOM.createPortal(<InactivityModal onCallback={onInactivityCallback} />, modalRootElement);
    default:
      throw Error('Unexpected state');
  }
};
