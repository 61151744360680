import styled from 'styled-components';

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px;
  background-color: var(--tia-body-bg, #fafbfd);
  grid-auto-rows: minmax(60px, auto);

  & > * {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  height: calc(100vh - 112px);
  overflow: scroll;
  width: 100%;
  padding: 32px 24px;
  display: flex;
  background-color: var(--tia-body-bg, #fafbfd);
`;

export const FullWidth = styled.div`
  grid-column: 1/3;
`;

export const SingleColumn = styled.div`
  height: 0;
  grid-template-columns: 1fr;
  display: grid;
  gap: 24px;
`;
