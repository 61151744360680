import i18nInit from './i18n';
import { jotaiStore } from './utils/apiConnector';
import { corporateEndpointAtom, spdSalesUiApiAtom, dateFormatAtom } from './utils/atoms/atoms';

export interface ITiaWidgetOptions {
  audience: string;
  issuer: string;
  clientID: string;
  agentApiUri: string;
  corporateApiUri: string;
  customerApiUri: string;
  spdSalesUiApi: string;
  supportedLanguages: string;
  fallbackLanguage: string;
  contextPath: string;
  sessionTimeout: string;
  sessionExpiringModalTimeout: string;
  twoWayCommunicationWidgetApi: string;
  twoWayCommunicationWidgetAudienceApi: string;
  twoWayBadgeRefreshTime: string;
  tokenEndpoint: string;
  authorizationEndpoint: string;
  userinfoEndpoint: string;
  logoutEndpoint: string;
  jwksUri: string;
  revocationEndpoint: string;
  clientSecret: string;
  manualUri: string;
  dateFormat: string;
}

declare global {
  interface Window {
    corpEnv: ITiaWidgetOptions;
    globalConfiguration: any;
  }
}

const fetchConfigurations = async () => {
  try {
    const globalConfiguration = await fetch(
      `${process.env.PUBLIC_URL}/configuration/global-config.json`
    ).then((response) => response.json());

    const response = await Promise.all([globalConfiguration]);

    return {
      globalConfiguration: response[0],
    };
  } catch (err) {
    console.error('Error: ', err);
  }
};

/**
 * Will register configurable env variables and attach them to the window object in window.corpEnv
 * From here variables can be requested as window.corpEnv.variableName
 *
 * In production:
 *    Will fetch variables from json file from public/environment/environment.json
 *    The json should be complient with the ITiaWidgetOptions interface definition above
 *
 * In development:
 *    Will read from .env.development file and attach the associated variables
 *
 * NB: register() is asynchronous, and will have to be
 * awaited before mounting the root component to the DOM
 */
const register = async (): Promise<void> => {
  let options: ITiaWidgetOptions;
  const configurations = await fetchConfigurations();
  const defaultDateFormat = 'DD-MM-YYYY';

  if (process.env.NODE_ENV === 'production') {
    const optionsJson = await fetch(`${process.env.PUBLIC_URL}/environment/environment.json`);

    options = await optionsJson.json();

    if (options && !options.dateFormat) {
      options.dateFormat = defaultDateFormat;
    }
  } else {
    options = {
      issuer: process.env.REACT_APP_ISSUER || '',
      clientID: process.env.REACT_APP_CLIENT_ID || '',
      agentApiUri: process.env.REACT_APP_CORPORATE_API || '',
      corporateApiUri: process.env.REACT_APP_CORPORATE_API || '',
      customerApiUri: process.env.REACT_APP_CORPORATE_API || '',
      spdSalesUiApi: process.env.REACT_APP_CORPORATE_API || '',
      audience: process.env.REACT_APP_AUDIENCE || '',
      supportedLanguages: process.env.REACT_APP_SUPPORTED_LANGUAGES || '',
      fallbackLanguage: process.env.REACT_APP_FALLBACK_LANGUAGE || '',
      sessionTimeout: process.env.REACT_APP_SESSION_TIMEOUT || '',
      sessionExpiringModalTimeout: process.env.REACT_APP_SESSION_EXPIRING_MODAL_TIMEOUT || '',
      contextPath: '',
      twoWayCommunicationWidgetApi: process.env.REACT_APP_TWO_WAY_COMMUNICATION_WIDGET_API || '',
      twoWayCommunicationWidgetAudienceApi: process.env.REACT_APP_TWO_WAY_COMMUNICATION_WIDGET_AUDIENCE || '',
      twoWayBadgeRefreshTime: process.env.REACT_APP_TWO_WAY_BADGE_REFRESH_TIME || '',
      tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || '',
      authorizationEndpoint: process.env.REACT_APP_AUTH_ENDPOINT || '',
      userinfoEndpoint: process.env.REACT_APP_USER_INFO_ENDPOINT || '',
      logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT || '',
      jwksUri: process.env.REACT_APP_JWKS_URI || '',
      revocationEndpoint: process.env.REACT_APP_REVOCATION_ENDPOINT || '',
      clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
      manualUri: process.env.REACT_APP_MANUAL || '',
      dateFormat: process.env.REACT_APP_DATE_FORMAT || defaultDateFormat,
    };
  }

  jotaiStore.set(corporateEndpointAtom, options.corporateApiUri || '');
  jotaiStore.set(spdSalesUiApiAtom, options.spdSalesUiApi || '');
  jotaiStore.set(dateFormatAtom, options.dateFormat || '');

  window.corpEnv = options;
  if (configurations) {
    window.globalConfiguration = configurations.globalConfiguration;
  }

  i18nInit(options.supportedLanguages, options.fallbackLanguage);
};

export default register;
