import React, { FC, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../utils/language';

interface IProps {
  configName: string;
  partyId?: string | number;
  handleCancel?: (event: Event) => void;
  handleSubmit?: (event: Event) => void;
  handleEdit?: (event: Event) => void;
  handlePayment?: (event: Event) => void;
}

const CustomUiWidget: FC<IProps> = ({ configName, partyId, handleSubmit, handleEdit, handleCancel, handlePayment }) => {
  const widgetRef = useRef<HTMLElement>();
  // t is required to trigger a page update when i18n.changeLanguage() is used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation('tia-custom-ui-widget');
  const cancelListener = useCallback(handleCancel || ((): void => window.history.back()), []);
  const submitListener = useCallback(
    handleSubmit || ((): void => console.error('No action assigned to cui-submit')),
    []
  );
  const editListener = useCallback(handleEdit || ((): void => console.error('No action assigned to cui-edit')), []);
  const paymentListener = useCallback(
    handlePayment || ((): void => console.error('No action assigned to cui-payment')),
    []
  );
  const widgetLocalization = JSON.stringify(t('widget', { returnObjects: true }));

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const widgetHtml = widgetRef.current;
    widgetHtml.addEventListener('cui-cancel', cancelListener);
    widgetHtml.addEventListener('cui-submit', submitListener);
    widgetHtml.addEventListener('cui-edit', editListener);
    widgetHtml.addEventListener('cui-payment', paymentListener);

    return (): void => {
      widgetHtml.removeEventListener('cui-cancel', cancelListener);
      widgetHtml.removeEventListener('cui-submit', submitListener);
      widgetHtml.removeEventListener('cui-edit', editListener);
      widgetHtml.removeEventListener('cui-payment', paymentListener);
    };
  }, [cancelListener, submitListener, editListener, paymentListener]);

  return (
    <tia-custom-ui-widget
      configName={configName}
      partyId={partyId}
      ref={widgetRef}
      translation={widgetLocalization}
      language={getLanguage()}
    />
  );
};

export default CustomUiWidget;
