import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import IPage from '@tia/corporate-api-connector/dist/model/IPage';
import calculatePagination from '../../utils/calculatePagination';
import generateRandomString from '../../utils/generateRandomString';
import styles from './tablePagination.module.scss';

interface IProps {
  paginationInfo: IPage;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (pageNumber: number) => void;
}

const TablePagination: FC<IProps> = (props) => {
  const previousPageButtonDisabled = props.paginationInfo.number - 1 === 0;
  const nextPageButtonDisabled = props.paginationInfo.number === props.paginationInfo.totalPages;
  const paginationArray = calculatePagination(props.paginationInfo.number, props.paginationInfo.totalPages);
  const { t } = useTranslation();

  const handlePaginationClick = (pageNumber: number): void => {
    props.setCurrentPage(pageNumber);
    props.onPageChange(pageNumber);
  };

  const handlePreviousPageClick = (): void => {
    if (!previousPageButtonDisabled) {
      props.setCurrentPage(props.paginationInfo.number - 1);
      props.onPageChange(props.paginationInfo.number - 1);
    }
  };

  const handleNextPageClick = (): void => {
    if (!nextPageButtonDisabled) {
      props.setCurrentPage(props.paginationInfo.number + 1);
      props.onPageChange(props.paginationInfo.number + 1);
    }
  };

  const renderPageNumberButton = (
    pageNumber: number,
    buttonType: string,
    onClick: () => void,
    disabled = false
  ): JSX.Element => (
    <tia-button
      onClick={onClick}
      key={pageNumber}
      name={pageNumber}
      type={buttonType}
      shrink="true"
      disabled={disabled}
    />
  );

  const renderPageNumbers = paginationArray.map((pageNumber) => {
    if (isNaN(pageNumber)) {
      return (
        <span key={generateRandomString()} className={styles.paginationDots}>
          ...
        </span>
      );
    }

    if (pageNumber === props.paginationInfo.number) {
      return renderPageNumberButton(pageNumber, 'ghost-secondary', () => null, true);
    }

    return renderPageNumberButton(pageNumber, 'minimal', () => handlePaginationClick(pageNumber));
  });

  return (
    <div className={styles.paginationContainer}>
      <span className={styles.paginationResult}>
        {props.paginationInfo.numberOfElements} {t('employeesTable.paginationResults')}
      </span>
      <div className={styles.paginationButtons}>
        <tia-button
          onClick={handlePreviousPageClick}
          name=""
          icon="chevron_left"
          type="minimal"
          size="default"
          shrink="true"
          disabled={previousPageButtonDisabled}
          full="false"
          loading="false"
        />
        {renderPageNumbers}
        <tia-button
          onClick={handleNextPageClick}
          name=""
          icon="chevron_right"
          type="minimal"
          size="default"
          shrink="true"
          disabled={nextPageButtonDisabled}
          full="false"
          loading="false"
        />
      </div>
    </div>
  );
};

export default TablePagination;
