import CorporateApiConnector from '@tia/corporate-api-connector';
import showToastMessage, { ToastTypes } from './showToastMessage';
import { IUiConfigParameters } from '@tia/corporate-api-connector/dist/model/IUiConfiguration';
import IParty from '@tia/corporate-api-connector/dist/model/IParty';
import { fireErrorNotification } from './notification';
import { TFunction } from 'i18next';
import IWrappedResponse from "@tia/corporate-api-connector/dist/interfaces/IWrappedResponse";

interface IPartyResponse {
  party: IParty;
}

let isSubmitting = false;

const getHandleSubmit = (
  configName: string,
  connector: CorporateApiConnector,
  reroutingCallback: (partyId: string) => void,
  triggerInviteAdminFlow: boolean,
  t: TFunction,
  id?: string,
  successMessage?: string
) => async (event: Event): Promise<void> => {
  const { detail } = event as CustomEvent;
  const formValues = detail;
  let resData: IWrappedResponse<Map<string, unknown>>;

  if (!isSubmitting) {
    isSubmitting = true;
    try {
      if (id) {
        resData = await connector.uiConfiguration.updateEntities(
          configName,
          formValues,
          {
            partyId: id,
          } as IUiConfigParameters,
          triggerInviteAdminFlow
        );
      } else {
        resData = await connector.uiConfiguration.updateEntities(
          configName,
          formValues,
          undefined,
          triggerInviteAdminFlow
        );
      }
      if (!resData.successful && resData.error) {
        resData.error.forEach((error) => fireErrorNotification(error, t));
        isSubmitting = false;

        return;
      }

      const partyResp = (resData.data as unknown) as IPartyResponse;
      const partyId = partyResp.party.id;

      showToastMessage({ message: successMessage || 'Successful', type: ToastTypes.success });
      isSubmitting = false;
      reroutingCallback(partyId.toString());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.groupCollapsed(`Failed updating entities for configName: ${configName}`);
      console.error(error);
      // eslint-disable-next-line no-console
      console.groupEnd();
      showToastMessage({ message: 'Error', type: ToastTypes.error });
      isSubmitting = false;
    }
  }
};

export default getHandleSubmit;
