import React, { useContext, useEffect, useRef } from 'react';
import { FullWidth } from '../../styles/ViewGrid/ViewGrid.styled';
import ViewGrid from '../../styles/ViewGrid/ViewGrid';
import { getLanguage } from '../../utils/language';
import { TwoWayContext } from '../../utils/TwoWayContext';

export const ContactUs: React.FC = () => {
  const twoWayRef = useRef<HTMLElement>(null);
  const { sendMessage } = useContext(TwoWayContext);

  const handleSendMessage = (): void => {
    if (sendMessage) {
      sendMessage();
    }
  };

  useEffect(() => {
    const element = twoWayRef.current;
    if (element) {
      element.addEventListener('tia-two-way-communication-widget-send-message', handleSendMessage);

      return (): void => {
        element.removeEventListener('tia-two-way-communication-widget-send-message', handleSendMessage);
      };
    }
  }, [sendMessage]);

  return (
    <ViewGrid>
      <FullWidth>
        <tia-two-way-messaging-widget
          ref={twoWayRef}
          widgetMode="initiator"
          api={window.corpEnv.twoWayCommunicationWidgetApi}
          language={getLanguage()}
          dateLocale="nl"
          dateFormatOptions={JSON.stringify({
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
        />
      </FullWidth>
    </ViewGrid>
  );
};
