import React, { FC, useContext, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IPolicy from '@tia/corporate-api-connector/dist/model/IPolicy';
import CustomUiWidget from '../../widget/CustomUiWidget/CustomUi.widget';
import PolicyListWidget from '../../widget/PolicyListWidget/PolicyList.widget';
import ViewGrid from '../../styles/ViewGrid/ViewGrid';
import { UserContext } from '../../utils/UserContext';
import DocumentsWidget from '../../widget/DocumentsWidget/Documents.widget';
import { SingleColumn } from '../../styles/ViewGrid/ViewGrid.styled';
import { getRouteToDashboardViewPolicy, getRouteToDashboardEditCorporation } from '../../routes';
import { getLanguage } from '../../utils/language';
import { CreatePaymentDetailComponent } from './Payment/CreatePaymentDetail';

const Dashboard: FC<RouteComponentProps> = (props) => {
  const identityType = useContext(UserContext);

  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);

  const handlePolicyClick = (policyDetails: IPolicy): void => {
    props.history.push(getRouteToDashboardViewPolicy(policyDetails.sipasUuid, policyDetails.policyStatus));
  };

  const handleEditEvent = (): void => {
    props.history.push(getRouteToDashboardEditCorporation());
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  return (
    <ViewGrid>
      <SingleColumn>
        <CustomUiWidget
          configName={'CorporateDetails'}
          partyId={identityType.corporatefirm.user_id}
          handleEdit={handleEditEvent}
          handlePayment={() => setShowPaymentModal(true)}
        />
        <DocumentsWidget partyId={identityType.corporatefirm.user_id} language={getLanguage()} />
      </SingleColumn>
      <PolicyListWidget
        policyStatus="All"
        partyId={identityType.corporatefirm.user_id}
        onPolicyClick={handlePolicyClick}
        underlyingPolicies={false}
        language={getLanguage()}
      />
      <CreatePaymentDetailComponent
        partyId={identityType.corporatefirm.user_id}
        showPaymentModal={showPaymentModal}
        closePaymentModal={closePaymentModal}
      />
    </ViewGrid>
  );
};

export default withRouter(Dashboard);
