import React, { FC, MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import IPage from '@tia/corporate-api-connector/dist/model/IPage';
import { getRouteToEmployeesViewParty } from '../../routes';
import { apiConnector } from '../../utils/apiConnector';
import PanelHeader from './PolicyPanelHeader';
import IUnderlyingParty from '@tia/corporate-api-connector/dist/model/IUnderlyingParty';
import PolicyRow, { LoadingRow, NoEmployeesRow, NoSelectedRow } from './PolicyRow';
import TablePagination from '../tablePagination/TablePagination.component';
import styles from './employeesSection.module.scss';
import { getPolicyStatusLabel } from '../../utils/getPolicyStatusLabel';
import PolicyStatus from '@tia/corporate-api-connector/dist/model/PolicyStatus';

interface IProps extends RouteComponentProps {
  routeCreateEmployeeHandler: () => void;
}

const defaultPaginationInfo: IPage = {
  totalPages: 0,
  number: 0,
  numberOfElements: 0,
  first: true,
  last: true,
  size: 0,
  totalElements: 0,
};

const delay = ((): ((callback: (...args: unknown[]) => void, ms: number) => void) => {
  let timer: number;

  return (callback: (...args: unknown[]) => void, ms: number): void => {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

const EmployeesSection: FC<IProps> = (props) => {
  const [filterData, setFilterData] = useState<{
    searchCivilRegCode?: string;
    searchName?: string;
    flexFieldSearchParams?: string;
    policyStatus?: PolicyStatus;
  }>({
    searchName: undefined,
    flexFieldSearchParams: undefined,
    searchCivilRegCode: undefined,
    policyStatus: undefined,
  });
  const [data, setData] = useState<IUnderlyingParty[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPage>(defaultPaginationInfo);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedPolicyNo, setSelectedPolicyNo] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const pageSize = 25;
  const { t } = useTranslation();

  const handleEmployeeRoute = (e: MouseEvent): void => {
    props.history.push(getRouteToEmployeesViewParty(e.currentTarget.id));
  };

  const handleSelectChange = (value: string): void => {
    if (!value) {
      setPaginationInfo(defaultPaginationInfo);
    }

    setSelectedPolicyNo(value);
  };

  const handleSearch = (searchName: string): void => {
    setFilterData({
      ...filterData,
      searchName,
    });
  };

  const onTablePageChanged = async (pageNo: number): Promise<void> => {
    setLoading(true);
    const policies = await apiConnector().policy.getUnderLyingPoliciesWithPagination({
      masterPolicyNo: selectedPolicyNo,
      apiParams: {
        page: pageNo.toString(),
        size: pageSize.toString(),
      },
    });

    populateTable(policies);
  };

  const populateTable = (policies: { data: IUnderlyingParty[]; page: IPage }): void => {
    setData(policies.data || []);
    if (policies.page) {
      setPaginationInfo(policies.page);
    }

    setLoading(false);
  };

  const fetchData = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const policies = await apiConnector().policy.getUnderLyingPoliciesWithPagination({
        masterPolicyNo: selectedPolicyNo,
        apiParams: {
          page: currentPage.toString(),
          size: pageSize.toString(),
        },
        ...filterData,
      });

      populateTable(policies);
    } catch (e) {
      console.error('Could not fetch underlying policies');
      setLoading(false);
    }
  }, [filterData, selectedPolicyNo]);

  useEffect(() => {
    if (selectedPolicyNo) {
      setPaginationInfo(defaultPaginationInfo);
      delay(fetchData, 400);
    }
  }, [selectedPolicyNo, fetchData]);

  const RenderEmptyTable = ({ children }: { children: ReactNode }): JSX.Element => {
    return (
      <tia-table-row>
        <div>
          <div style={{ display: 'none' }} />
          <div style={{ display: 'none' }} />
          <div style={{ display: 'none' }} />
          <div style={{ display: 'none' }} />
          <div style={{ textAlign: 'center', minWidth: '100%' }}>{children}</div>
          <div style={{ display: 'none' }} />
          <div style={{ display: 'none' }} />
          <div style={{ display: 'none' }} />
          <div style={{ display: 'none' }} />
        </div>
      </tia-table-row>
    );
  };

  const renderRows = (): JSX.Element[] | JSX.Element => {
    if (loading) {
      return (
        <RenderEmptyTable>
          <LoadingRow />
        </RenderEmptyTable>
      );
    }

    if (!selectedPolicyNo) {
      return (
        <RenderEmptyTable>
          <NoSelectedRow />
        </RenderEmptyTable>
      );
    }

    if (!data.length) {
      return (
        <RenderEmptyTable>
          <NoEmployeesRow />
        </RenderEmptyTable>
      );
    }

    return data.map((policy, index) => {
      return <PolicyRow key={index} {...policy} routeHandler={handleEmployeeRoute} onRowChange={fetchData} />;
    });
  };

  const renderTable = (): JSX.Element => {
    const handleSearchFlexFields = (flexFieldSearchParams: string): void => {
      setFilterData({
        ...filterData,
        flexFieldSearchParams: `c10=${flexFieldSearchParams}`,
      });
    };

    const handleSearchCivilRegCode = (searchCivilRegCode: string): void => {
      setFilterData({
        ...filterData,
        searchCivilRegCode,
      });
    };

    const handleStatusChange = (policyStatus: string): void => {
      setFilterData({
        ...filterData,
        policyStatus: policyStatus as PolicyStatus,
      });
    };

    const handleSearchName = (searchName: string): void => {
      setFilterData({
        ...filterData,
        searchName,
      });
    };

    const renderSelectStatusOptions = (): JSX.Element[] => {
      const getTranslation = (status: PolicyStatus): string => {
        return t(getPolicyStatusLabel(status).labelKey);
      };

      const options = [
        { label: getTranslation(PolicyStatus.QUOTE), value: PolicyStatus.QUOTE },
        { label: getTranslation(PolicyStatus.DECLINED_QUOTE), value: PolicyStatus.DECLINED_QUOTE },
        { label: getTranslation(PolicyStatus.REFERRED_QUOTE), value: PolicyStatus.REFERRED_QUOTE },
        { label: getTranslation(PolicyStatus.SUSPENDED_QUOTE), value: PolicyStatus.SUSPENDED_QUOTE },
        { label: getTranslation(PolicyStatus.POLICY), value: PolicyStatus.POLICY },
        { label: getTranslation(PolicyStatus.REFERRED_POLICY), value: PolicyStatus.REFERRED_POLICY },
        { label: getTranslation(PolicyStatus.SUSPENDED_POLICY), value: PolicyStatus.SUSPENDED_POLICY },
        { label: getTranslation(PolicyStatus.QUOTE_ON_POLICY), value: PolicyStatus.QUOTE_ON_POLICY },
        { label: getTranslation(PolicyStatus.CANCELLED_POLICY), value: PolicyStatus.CANCELLED_POLICY },
        { label: getTranslation(PolicyStatus.CANCELLED_QUOTE), value: PolicyStatus.CANCELLED_QUOTE },
        { label: getTranslation(PolicyStatus.SUSPENDED_QUOTE_PROP), value: PolicyStatus.SUSPENDED_QUOTE_PROP },
      ];

      return options.map((option) => (
        <div
          key={option.value}
          className={option.value === filterData.policyStatus ? styles['option--selected'] : styles.option}
          data-value={option.value}
        >
          {option.label}
        </div>
      ));
    };

    return (
      //policy holder id, civil reg code, policy no, status, start date, end date, annual premium, cost center, delete icon
      <tia-table widths="[18, 11, 11, 10, 11, 11, 13, 10, 5]">
        <tia-table-header>
          <div>
            <Input label={t('employeesTable.columns.policyHolder')} searchCallback={handleSearchName} />
          </div>
          <div>
            <Input label={t('employeesTable.columns.civilRegCode')} searchCallback={handleSearchCivilRegCode} />
          </div>
          <div className={styles.employeeTableCell}>{t('employeesTable.columns.policyNo')}</div>
          <div>
            <Select label={t('employeesTable.columns.status')} handleSelect={handleStatusChange}>
              {renderSelectStatusOptions()}
            </Select>
          </div>
          <div className={styles.employeeTableCell}>{t('employeesTable.columns.startDate')}</div>
          <div className={styles.employeeTableCell}>{t('employeesTable.columns.endDate')}</div>
          <div className={styles.employeeTableCell}>{t('employeesTable.columns.annualPremium')}</div>
          <div>
            {/*// Temporary solution for now, later on the field will be generated dynamically*/}
            <Input label={t('employeesTable.columns.c10')} searchCallback={handleSearchFlexFields} />
          </div>
          <div className={styles.employeeTableCell} />
        </tia-table-header>
        {renderRows()}
      </tia-table>
    );
  };

  const renderPagination = (): JSX.Element | null => {
    if (!paginationInfo.numberOfElements) {
      return null;
    }

    return (
      <TablePagination
        paginationInfo={paginationInfo}
        setCurrentPage={setCurrentPage}
        onPageChange={onTablePageChanged}
      />
    );
  };

  return (
    <div className={styles.sectionContainer}>
      <tia-panel title={t('employeesTable.title')} width="100%">
        <PanelHeader
          {...props}
          selectHandler={handleSelectChange}
          selectedPartyId={selectedPolicyNo}
          routeCreateEmployeeHandler={props.routeCreateEmployeeHandler}
          search={handleSearch}
        />
        {renderTable()}
      </tia-panel>
      {renderPagination()}
    </div>
  );
};

const Select: FC<{ label: string; handleSelect: (value: string) => void }> = (props): JSX.Element => {
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = (): void => {
    if (isExpanded) {
      setIsExpanded(false);

      return;
    }

    setIsExpanded(true);
    setTimeout(() => window.addEventListener('click', () => setIsExpanded(false), { once: true }));
  };

  const handleClear = (): void => {
    setValue('');
    setLabel('');
    props.handleSelect('');
  };

  const handleChange = (event: MouseEvent): void => {
    const target = event.target as HTMLElement;

    if (!target.hasAttribute('data-value')) {
      // We need a click on tia-option in order to get a proper value
      return;
    }

    const newValue = target.getAttribute('data-value') || '';

    setLabel(target.innerText);
    setValue(newValue);
    props.handleSelect(newValue);
  };

  return (
    <div className={styles.searchCell}>
      <tia-icon name="search" size="default" color="#405e6c" />

      <div onClick={toggleExpansion} className={styles.dropdownValue}>
        {label || props.label}
      </div>

      {value && (
        <span className={styles.dropdownClear} onClick={handleClear}>
          <tia-icon name="close" color="#405e6c" />
        </span>
      )}

      <div className={styles.dropdownContainer}>
        {isExpanded && (
          <div className={styles.employeeDropdown} onClick={handleChange}>
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
};

const Input: FC<{ label: string; searchCallback: (value: string) => void }> = (props): JSX.Element => {
  const [inputState, setInputState] = useState('');

  const handleInput = useCallback(
    (event: React.FormEvent<HTMLInputElement>): void => {
      props.searchCallback(event.currentTarget.value);
    },
    [props.searchCallback]
  );

  return (
    <div className={`${styles.searchCell} ${inputState}`}>
      <tia-icon name="search" size="default" color="#405e6c" />
      <input
        onInput={handleInput}
        placeholder={props.label}
        type="text"
        onBlur={(): void => setInputState('')}
        onFocus={(): void => setInputState(styles.searchButtonActive)}
      />
    </div>
  );
};

export default withRouter(EmployeesSection);
