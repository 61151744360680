import React, { FC, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserContext } from '../../../utils/UserContext';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import { getRouteToDashboardEditCorporation } from '../../../routes';
import AgentSalesFlowWidget from '../../../widget/AgentSalesFlowWidget/AgentSalesFlow.widget';
import usePolicyStatus from '../../../hooks/usePolicyStatus';
import { getLanguage } from '../../../utils/language';

const Policy: FC<RouteComponentProps> = (props) => {
  const urlParams = props.match.params as { policyId: string };
  const policyId = urlParams.policyId;
  const identityTypes = useContext(UserContext);
  const policyStatus = usePolicyStatus(props.location.search);

  const handleEditEvent = (): void => {
    props.history.push(getRouteToDashboardEditCorporation());
  };

  return (
    <ViewGrid>
      <CustomUiWidget
        configName={'CorporateDetails'}
        partyId={identityTypes.corporatefirm.user_id}
        handleEdit={handleEditEvent}
      />
      <AgentSalesFlowWidget
        language={getLanguage()}
        policyUuid={policyId}
        partyId={identityTypes.corporatefirm.user_id}
        policyStatus={policyStatus}
      />
    </ViewGrid>
  );
};

export default withRouter(Policy);
