import { TiaAuthClient, User } from '@tia/authenticator';
import { useEffect, useMemo, useState } from 'react';
import { getAuthOptions } from '../components/auth/CorporateAuthProvider';
import { getUserIdentityTypes } from '../utils/UserContext';
import { jotaiStore } from '../utils/apiConnector';
import { tcpAccessTokenAtom, tcpExpiresAtAtom } from '../utils/atoms/atoms';

export const TCP_ACCESS_TOKEN = 'tia.widgets.tcp.accessToken';

export const useTwoWayLogin = (userId: string) => {
  const authOptions = useMemo(getAuthOptions, []);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const contextPath = window.corpEnv.contextPath || '';
  const contextLocation = contextPath ? `${window.location.origin}/${contextPath}` : window.location.origin;

  useEffect(() => {
    const twoWay = new TiaAuthClient({
      authOptions: {
        issuer: authOptions.issuer,
        successfulLogoutUri: authOptions.successfulLogoutUri,
        audience: window.corpEnv.twoWayCommunicationWidgetAudienceApi,
        clientId: authOptions.clientID,
        redirectUri: `${contextLocation}/callback`,
        scope: authOptions.scope,
        webStorageStateName: 'tcp',
        tokenEndpoint: authOptions.tokenEndpoint,
        authorizationEndpoint: authOptions.authorizationEndpoint,
        userinfoEndpoint: authOptions.userinfoEndpoint,
        logoutEndpoint: authOptions.logoutEndpoint,
        jwksUri: authOptions.jwksUri,
        revocationEndpoint: authOptions.revocationEndpoint,
        clientSecret: authOptions.clientSecret,
      },
    });
    const login = async () => {
      try {
        const user = await twoWay.silentLogin({ corporatefirm: userId });
        if (user) {
          jotaiStore.set(tcpAccessTokenAtom, user.access_token);
          jotaiStore.set(tcpExpiresAtAtom, `${user.expires_at * 1000}`);
          setIsAuthenticated(true);
        }
      } catch (e) {
        console.error(e);
        setIsAuthenticated(true);
      }
    };

    (async () => {
      const tcpUser = await twoWay.getUser();
      if (tcpUser) {
        if (getUserIdentityTypes(tcpUser).corporatefirm.user_id !== userId) {
          await login();
        } else {
          jotaiStore.set(tcpAccessTokenAtom, tcpUser.access_token);
        }

        setIsAuthenticated(true);
      } else {
        await login();
      }
    })();

    twoWay.addOnTokenRenewListener((user: User) => {
      if (user) {
        jotaiStore.set(tcpAccessTokenAtom, user.access_token);
        jotaiStore.set(tcpExpiresAtAtom, `${user.expires_at * 1000}`);
      }
    });
  }, [userId]);

  return isAuthenticated;
};
