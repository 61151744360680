import PolicyStatus from '@tia/corporate-api-connector/dist/model/PolicyStatus';

/**
 * Get a full label description based on a salesProduct status
 * @param status;
 */

export const getPolicyStatusLabel = (status: PolicyStatus): { color: string; labelKey: string } => {
  switch (status) {
    case PolicyStatus.POLICY:
      return { color: 'green', labelKey: 'policyStatus.policy' };
    case PolicyStatus.SUSPENDED_QUOTE:
      return { color: 'orange', labelKey: 'policyStatus.suspendedQuote' };
    case PolicyStatus.REFERRED_POLICY:
      return { color: 'orange', labelKey: 'policyStatus.referredPolicy' };
    case PolicyStatus.REFERRED_QUOTE:
      return { color: 'orange', labelKey: 'policyStatus.referredQuote' };
    case PolicyStatus.SUSPENDED_QUOTE_PROP:
      return { color: 'orange', labelKey: 'policyStatus.suspendedQuoteProp' };
    case PolicyStatus.SUSPENDED_POLICY:
      return { color: 'orange', labelKey: 'policyStatus.suspendedPolicy' };
    case PolicyStatus.DECLINED_QUOTE:
      return { color: 'red', labelKey: 'policyStatus.declinedQuote' };
    case PolicyStatus.QUOTE:
      return { color: 'red', labelKey: 'policyStatus.quote' };
    case PolicyStatus.QUOTE_ON_POLICY:
      return { color: 'red', labelKey: 'policyStatus.quoteOnPolicy' };
    case PolicyStatus.CANCELLED_POLICY:
      return { color: 'red', labelKey: 'policyStatus.cancelledPolicy' };
    case PolicyStatus.CANCELLED_QUOTE:
      return { color: 'red', labelKey: 'policyStatus.cancelledQuote' };
    case PolicyStatus.UNKNOWN:
      return { color: 'red', labelKey: 'policyStatus.unknown' };
    default:
      return { color: 'red', labelKey: 'policyStatus.unknown' };
  }
};
