import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import registerEnvironmentConfig from './registerEnvironmentConfig';
import './index.css';
import '@tia/ui-components';

registerEnvironmentConfig().then(() => ReactDOM.render(<App />, document.getElementById('root') as HTMLElement));

registerServiceWorker();
