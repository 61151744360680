import React from 'react';
import { useScript } from '../../hooks/useScript';

interface IRemoteResource {
  url: string;
  type?: 'module';
  scriptIdentifier: string;
  onLoad?: () => void;
  onError?: () => void;
}

/***
 * Component helps to load through component delcaration.
 **/
export const RemoteResource: React.FC<IRemoteResource> = (props) => {
  useScript({ src: props.url, type: props.type || '' }, props.scriptIdentifier, props.onLoad, props.onError);

  return null;
};
