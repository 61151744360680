import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import AgentSalesFlowWidget from '../../../widget/AgentSalesFlowWidget/AgentSalesFlow.widget';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import usePolicyId from '../../../hooks/usePolicyId';
import { getRouteToEmployeesEditEmployee } from '../../../routes';
import usePolicyStatus from '../../../hooks/usePolicyStatus';
import { getLanguage } from '../../../utils/language';

const EmployeePolicy: FC<RouteComponentProps> = (props) => {
  const urlParams = props.match.params as { partyId: string };
  const partyId = urlParams.partyId;
  const policyId = usePolicyId(props.location.search);
  const policyStatus = usePolicyStatus(props.location.search);
  const handleEdit = (): void => props.history.push(getRouteToEmployeesEditEmployee(partyId));

  return (
    <ViewGrid>
      <CustomUiWidget configName={'EmployeeDetails'} partyId={partyId} handleEdit={handleEdit} />
      <AgentSalesFlowWidget
        language={getLanguage()}
        policyUuid={policyId}
        partyId={partyId}
        policyStatus={policyStatus}
      />
    </ViewGrid>
  );
};

export default withRouter(EmployeePolicy);
