import React, { FC, useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import { getRouteToEmployeesViewParty, getRouteToEmployeesEditEmployee } from '../../../routes';
import AgentSalesFlowWidget from '../../../widget/AgentSalesFlowWidget/AgentSalesFlow.widget';
import showToastMessage, { ToastTypes } from '../../../utils/showToastMessage';
import { apiConnector } from '../../../utils/apiConnector';
import IPolicy from '@tia/corporate-api-connector/dist/model/IPolicy';
import { IIdentity } from '../../../interfaces/IIdToken.interface';
import { getLanguage } from '../../../utils/language';
import { UserContext } from '../../../utils/UserContext';

interface IProps extends RouteComponentProps {
  identityTypes: { [key: string]: IIdentity };
}

interface IMappedMasterPolicy extends IPolicy {
  key: string;
  value: string;
}

/* eslint-disable react-hooks/exhaustive-deps */
const NewQuoteView: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLElement>();
  const radioButtonRef = useRef<HTMLElement>();
  const urlParams = props.match.params as { partyId: string };
  const partyId = urlParams.partyId;
  const [selectedMasterPolicy, setSelectedMasterPolicy] = useState<IMappedMasterPolicy>();
  const [masterPolicies, setMasterPolicies] = useState<IMappedMasterPolicy[]>([]);
  const [masterPoliciesLoading, setMasterPoliciesLoading] = useState<boolean>(false);
  const [modalVisibility, setModalVisibility] = useState<boolean>(true);
  const [selectedUnderlyingPolicy, setSelectedUnderlyingPolicy] = useState<string>('');
  const identityTypes = useContext(UserContext);

  const handleEditEvent = (): void => {
    props.history.push(getRouteToEmployeesEditEmployee(partyId));
  };

  const handleRouteChange = (e: Event): void => {
    const { detail } = e as CustomEvent;

    if (detail && detail.actionType === 'primary') {
      showToastMessage({ message: 'Successful', type: ToastTypes.success });
      props.history.push(getRouteToEmployeesViewParty(partyId));
    }
  };

  const fetchMasterPolicyList = async (): Promise<void> => {
    setMasterPoliciesLoading(true);

    try {
      const response = await apiConnector().policy.getPolicies('All', identityTypes.corporatefirm.user_id, true);

      setMasterPoliciesLoading(false);

      if (response.data) {
        const mappedMasterPolicies: IMappedMasterPolicy[] = response.data.map((policy: IPolicy) => ({
          ...policy,
          key: policy.sipasUuid,
          value: `${policy.sipasProdName} - ${policy.policyNo[0]}`,
        }));

        setSelectedMasterPolicy(mappedMasterPolicies[0]);
        setMasterPolicies(mappedMasterPolicies);
      }

      if (!response?.data?.length) {
        setModalVisibility(false);
        showToastMessage({ message: 'This party does not have any master policies', type: ToastTypes.warning });
      }
    } catch (err) {
      setMasterPoliciesLoading(false);
      console.error('Error: ', err);
    }
  };

  const handleSelectedMasterPolicy = async (): Promise<void> => {
    if (selectedMasterPolicy) {
      try {
        const salesPolicyByUuidResponse = await apiConnector().salesPolicies.getSalesPolicyByUUID(
          selectedMasterPolicy.key
        );

        if (salesPolicyByUuidResponse.data) {
          const underlyingSalesProduct = salesPolicyByUuidResponse.data.salesProductUiProperties.opt.find(
            (opt) => opt.key === 'underlyingSalesProduct'
          );

          if (underlyingSalesProduct) {
            setSelectedUnderlyingPolicy(underlyingSalesProduct.value);
          } else {
            showToastMessage({ message: 'Underlying policy not found', type: ToastTypes.error });
          }
        }

        setModalVisibility(false);
      } catch (err) {
        showToastMessage({ message: 'Underlying policy not found', type: ToastTypes.error });
        console.error('TCL: handleSelectedMasterPolicy -> err', err);
      }
    }
  };

  useEffect(() => {
    if (selectedMasterPolicy && !modalVisibility) {
      handleSelectedMasterPolicy();
    }
  }, [selectedMasterPolicy, modalVisibility]);

  useEffect(() => {
    fetchMasterPolicyList();

    if (modalRef.current) {
      modalRef.current.addEventListener('primary', () => setModalVisibility(false), false);
    }
  }, []);

  const selectedMasterPolicyOnClick = (e: Event): void => {
    const key = (e as CustomEvent).detail.key;
    const foundMasterPolicy =
      masterPolicies && masterPolicies.find((masterPolicy: IMappedMasterPolicy) => masterPolicy.key === key);

    setSelectedMasterPolicy(foundMasterPolicy);
  };

  useEffect(() => {
    if (radioButtonRef.current) {
      radioButtonRef.current.addEventListener('change', selectedMasterPolicyOnClick, false);
    }
  }, [masterPolicies]);

  const renderModalContent = (): JSX.Element => {
    if (masterPoliciesLoading) {
      return (
        <TiaSpinnerContainer>
          <tia-spinner size="default" color="secondary" />
        </TiaSpinnerContainer>
      );
    }

    return (
      <ModalContentContainer>
        <tia-radio
          ref={radioButtonRef}
          size="default"
          selected-key={selectedMasterPolicy && selectedMasterPolicy.key}
          radio-buttons={JSON.stringify(masterPolicies)}
        />
      </ModalContentContainer>
    );
  };

  const renderAgentSalesFlow = (): JSX.Element | null => {
    if (!selectedUnderlyingPolicy) {
      return null;
    }

    const inheritanceUuid = selectedMasterPolicy?.sipasUuid;
    const queryParameters = {
      masterPolicyNo: selectedMasterPolicy?.policyNo[0],
    };

    return (
      <AgentSalesFlowWidget
        language={getLanguage()}
        disableProductSelection={true}
        partyId={partyId}
        productName={selectedUnderlyingPolicy}
        handleRouteChange={handleRouteChange}
        queryParameters={queryParameters}
        inheritanceUuid={inheritanceUuid}
      />
    );
  };

  return (
    <>
      <tia-modal
        ref={modalRef}
        visible={modalVisibility}
        title={t('employeeNewQuote.selectMasterPolicyModal.title')}
        primarytext={t('employeeNewQuote.selectMasterPolicyModal.continue')}
        canceltext={t('employeeNewQuote.selectMasterPolicyModal.cancel')}
        id="master-policy-modal"
        headericon="delete_forever"
      >
        {renderModalContent()}
      </tia-modal>
      <ViewGrid>
        <CustomUiWidget configName={'EmployeeDetails'} partyId={partyId} handleEdit={handleEditEvent} />
        {renderAgentSalesFlow()}
      </ViewGrid>
    </>
  );
};

export default withRouter(NewQuoteView);

const TiaSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ModalContentContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;
