import styled from 'styled-components';

export const StyledCardActions = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  margin-left: -3px;
  margin-right: -3px;

  * {
    margin-left: 3px;
    margin-right: 3px;
  }
`;

export const StyledCardHeader = styled.div`
  font-size: 20px;
  text-align: center;
  padding-bottom: 1rem;
`;

export const StyledCardContent = styled.div<{ withError?: boolean }>`
  white-space: pre-wrap;
  color: ${(props) => (props.withError ? 'var(--tia-color-error, "#f02b43")' : 'inherit')};
`;

export const StyledCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.625rem 0 rgb(0 0 0 / 5%);
  border: 0.0625rem solid #e2e5ea;
  padding: 2rem;
  color: rgba(0, 0, 0, 0.86);
`;
