import React from 'react';
import { withRouter } from 'react-router';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import { apiConnector } from '../../../utils/apiConnector';
import getHandleSubmit from '../../../utils/handleEntityFormSubmit';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import RolesTable from '../../../components/rolesTable/RolesTable.component';
import { useTranslation } from 'react-i18next';
import { StyledCard, StyledCardContent } from '../../../styles/Card.styled';
import { Link } from 'react-router-dom';

const CONFIG_NAME = 'InviteNewEmployee';

const Admin: React.FC = () => {
  const { t } = useTranslation();
  const handleSubmit = getHandleSubmit(CONFIG_NAME, apiConnector(), () => ({}), true, t, undefined, t('adminInvited'));

  return (
    <ViewGrid style={{ gridTemplateRows: '0fr' }}>
      <StyledCard
        style={{
          padding: '1rem',
          gridRow: '1 / 2',
          gridColumn: '1 / -1',
          maxHeight: '1rem',
          width: 'auto',
        }}
      >
        <StyledCardContent>
          <Link to="/contact-us" style={{ textDecoration: 'underline' }}>
            <span>{t('adminBannerMessage')}</span>
          </Link>
        </StyledCardContent>
      </StyledCard>
      <CustomUiWidget configName={'InviteNewEmployee'} handleSubmit={handleSubmit} />
      <RolesTable />
    </ViewGrid>
  );
};

export default withRouter(Admin);
