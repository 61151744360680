import React, { FC } from 'react';
import { Grid, Wrapper } from './ViewGrid.styled';
import { CSSProperties } from 'styled-components';

interface IProps {
  theme?: string;
  style?: CSSProperties;
}

const ViewGrid: FC<IProps> = (props) => (
  <Wrapper>
    <Grid style={props.style}  theme={props.theme}>{props.children}</Grid>
  </Wrapper>
);

export default ViewGrid;
