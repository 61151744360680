import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import { FullWidth } from '../../../styles/ViewGrid/ViewGrid.styled';
import { apiConnector } from '../../../utils/apiConnector';
import getHandleSubmit from '../../../utils/handleEntityFormSubmit';
import { getRouteToEmployeesViewParty } from '../../../routes';
import { useTranslation } from 'react-i18next';

const CONFIG_NAME = 'CreateEmployee';

const CreateEmployee: FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();

  const rerouteOnSubmit = (pid: string): void => {
    props.history.push(getRouteToEmployeesViewParty(pid));
  };

  const handleSubmit = getHandleSubmit(
    CONFIG_NAME,
    apiConnector(),
    rerouteOnSubmit,
    false,
    t,
    undefined,
    t('employeeCreated')
  );

  return (
    <ViewGrid>
      <FullWidth>
        <CustomUiWidget configName={CONFIG_NAME} handleSubmit={handleSubmit} />
      </FullWidth>
    </ViewGrid>
  );
};

export default withRouter(CreateEmployee);
