import { useState, useEffect } from 'react';

/**
 * Custom hook
 * Extract policy id from query params if present
 * @param params
 */
const usePolicyId = (params: string): string => {
  const [derivedPolicyId, setDerivedPolicyId] = useState<string>('');

  useEffect(() => {
    const formattedParams = new URLSearchParams(params);
    const policyId = formattedParams.get('policyId');

    if (policyId) {
      setDerivedPolicyId(policyId);
    }
  }, [params]);

  return derivedPolicyId;
};

export default usePolicyId;
