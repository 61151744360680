import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import showToastMessage, { ToastTypes } from '../../utils/showToastMessage';

interface IProps {
  partyId?: string;
  sipasUuid?: string;
  language: string;
}

const DocumentsWidget: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation('tia-documents-widget');
  const documentsWidgetRef = useRef<HTMLElement>(null);

  const handleDownloadAttachmentError = useCallback(({ detail: { message } }: CustomEvent) => {
    showToastMessage({ message, type: ToastTypes.error });
  }, []);

  useEffect(() => {
    if (documentsWidgetRef.current) {
      documentsWidgetRef.current.addEventListener('download-attachment-error' as any, handleDownloadAttachmentError);
    }

    return (): void => {
      documentsWidgetRef.current?.removeEventListener(
        'download-attachment-error' as any,
        handleDownloadAttachmentError
      );
    };
  }, []);

  const widgetLocalization = JSON.stringify(t('widget', { returnObjects: true }));

  return (
    <tia-documents-widget
      ref={documentsWidgetRef}
      localization={widgetLocalization}
      partyId={props.partyId}
      sipasUuid={props.sipasUuid}
    />
  );
};

export default DocumentsWidget;
