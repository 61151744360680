import React, { FC, useEffect, useRef } from 'react';
import IPolicy from '@tia/corporate-api-connector/dist/model/IPolicy';
import { useTranslation } from 'react-i18next';

interface IProps {
  partyId: string;
  policyStatus: string;
  showHeaderButton?: boolean;
  underlyingPolicies: boolean;
  language: string;
  onPolicyClick: (policyDetails: IPolicy) => void;
  onNewQuoteClick?: (e: Event) => void;
}

const PolicyListWidget: FC<IProps> = (props) => {
  const widgetRef = useRef<HTMLElement>();
  const { t } = useTranslation('tia-policy-list-widget');
  const widgetLocalization = JSON.stringify(t('widget', { returnObjects: true }));

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const element = widgetRef.current;

    element.addEventListener('dispatch-policy-details', (e: Event) => {
      props.onPolicyClick((e as CustomEvent).detail);
    });

    if (props.showHeaderButton) {
      element.addEventListener('dispatch-click-new-quote', (evt: Event) => {
        props.onNewQuoteClick && props.onNewQuoteClick(evt);
      });
    }
  }, [props]);

  return (
    <tia-policy-list-widget
      ref={widgetRef}
      policyStatus={props.policyStatus}
      partyId={props.partyId}
      showHeaderButton={props.showHeaderButton}
      underlyingPolicies={props.underlyingPolicies}
      localization={widgetLocalization}
    />
  );
};

export default PolicyListWidget;
