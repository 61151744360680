import globalConfigFallback from './global-config-fallback.json';

let globalConfig: any = null;

/**
 * This method is used to get global portal configuration specified in public/configuration/global-config.json.
 * If the configuration is not found in the above file, fallback value from global-config-fallback.json will be used instead.
 * @param {*} string string is the json path to the requested property etc "notification.duration.default"
 * @returns object
 */
export const getGlobalConfiguration = (s: string): any | undefined => {
  if (!window.globalConfiguration) {
    return getFallbackGlobalValue(s);
  }

  if (!globalConfig) {
    globalConfig = window.globalConfiguration;
  }

  const globalValue = jsonPathToValue(globalConfig, s);

  if (jsonPathWasNotFound(globalValue)) {
    return getFallbackGlobalValue(s);
  }

  return globalValue;
};

const getFallbackGlobalValue = (s: string): any => {
  const fallbackValue = jsonPathToValue(globalConfigFallback, s);

  if (jsonPathWasNotFound(fallbackValue)) {
    console.error(`No fallback value for global configuration ${s} was found`);
  }

  return fallbackValue;
};

/**
 * Returns true if featureResult is undefined.
 * @param {*} featureResult result of a call o jsonPathToValue
 * @returns boolean
 */
const jsonPathWasNotFound = (result: any): boolean => {
  return result === undefined;
};

/**
 * This method will recursively iterate the the json object, to find the path matching path and return that value.
 * Undefined will be returned if path is not found in the json object.
 * @param {*} jsonData json object used to lookup values
 * @param {*} path json path that will be used as lookup etc "customer.createCustomerDetail.customUiWidget"
 * @returns object
 */
export const jsonPathToValue = (jsonData: any, path?: any): any => {
  if (!(jsonData instanceof Object) || typeof path === 'undefined') {
    throw new Error('Not a valid JSON object');
  }

  const pathArray = path.split('.');

  try {
    return pathArray.reduce((accVal: any, p: any) => {
      if (!accVal) {
        accVal = jsonData;
      }

      if (accVal[p] !== null) {
        accVal = accVal[p];
      }

      return accVal;
    }, '');
  } catch {
    return undefined;
  }
};
