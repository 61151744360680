import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { convertISOToDateString } from '../../utils/dateFormatter';
import { getPolicyStatusLabel } from '../../utils/getPolicyStatusLabel';
import IUnderlyingParty, { IPartyPolicy } from '@tia/corporate-api-connector/dist/model/IUnderlyingParty';
import PolicyStatus from '@tia/corporate-api-connector/dist/model/PolicyStatus';
import styles from './employeesSection.module.scss';
import { DeleteButton } from './DeleteButton.component';

interface IRowProps extends IUnderlyingParty {
  routeHandler: (e: React.MouseEvent) => void;
  onRowChange: () => void;
}

const getEndDate = (policy: IPartyPolicy): string => convertISOToDateString(policy.coverEndDate);

const getStartDate = (policy: IPartyPolicy): string => {
  return convertISOToDateString(policy.firstStartDate);
};

const PolicyRow: FC<IRowProps> = (props: IRowProps) => {
  const mainPolicy = props.policies[0];
  const { color, labelKey } = getPolicyStatusLabel(mainPolicy.policyStatus as PolicyStatus);
  const { t } = useTranslation();

  const hasMultiplePolicies = props.policies.length > 1;

  const mainPolicyData = {
    policyNo: hasMultiplePolicies ? '' : mainPolicy.policyNo,
    policyTag: hasMultiplePolicies ? '' : <tia-tag label={t(labelKey)} color={color} read-only="true" />,
    endDate: hasMultiplePolicies ? '' : getEndDate(mainPolicy),
    annualAmount: hasMultiplePolicies ? '' : mainPolicy.annualAmount === null ? 0 : mainPolicy.annualAmount,
    currencyCode: hasMultiplePolicies ? '' : mainPolicy.currencyCode,
    civilRegCode: props.civilRegCode,
    // Temporary solution for now, later on the field will be generated dynamically
    c10: props.flexFields.c10,
    startDate: hasMultiplePolicies ? '' : getStartDate(mainPolicy),
    deleteButton: hasMultiplePolicies ? '' : <DeleteButton policy={mainPolicy} onDelete={props.onRowChange} />,
  };

  return (
    <tia-table-row expandable={hasMultiplePolicies} expanded="true">
      <div className={styles.clickableRow}>
        <div
          id={`${props.partyId}`}
          className={`${styles.nameLink} ${styles.employeeTableCell}`}
          onClick={props.routeHandler}
        >
          {props.forename} {props.surname}
        </div>
        <div className={styles.employeeTableCell}>{mainPolicyData.civilRegCode}</div>
        <div className={styles.employeeTableCell}>{mainPolicyData.policyNo}</div>
        <div className={styles.employeeTableCell}>{mainPolicyData.policyTag}</div>
        <div className={styles.employeeTableCell}>{mainPolicyData.startDate}</div>
        <div className={styles.employeeTableCell}>{mainPolicyData.endDate}</div>
        <div className={styles.employeeTableCell}>
          {mainPolicyData.annualAmount} {mainPolicyData.currencyCode}
        </div>
        <div className={styles.employeeTableCell}>{mainPolicyData.c10}</div>
        <div className={styles.employeeTableCell}>{mainPolicyData.deleteButton}</div>
      </div>
      {renderExpandableRows(props.policies, props.onRowChange)}
    </tia-table-row>
  );
};

const renderExpandableRows = (
  policies: IPartyPolicy[],
  onDelete: (policy: IPartyPolicy) => void
): JSX.Element[] | null => {
  if (policies.length === 1) {
    return null;
  }

  return policies.map((policy) => {
    const { color, labelKey } = getPolicyStatusLabel(policy.policyStatus as PolicyStatus);
    const { t } = useTranslation();

    return (
      <div key={policy.policyNoAlt}>
        {/* name column */}
        <div />
        {/* cpr column */}
        <div />
        {/* policy no */}
        <div>{policy.policyNo}</div>
        <div>
          {/* status column */}
          <tia-tag label={t(labelKey)} color={color} read-only="true" />
        </div>
        {/* start date column */}
        <div>{getStartDate(policy)}</div>
        {/* end date column */}
        <div>{getEndDate(policy)}</div>
        <div>
          {policy.annualAmount === null ? 0 : policy.annualAmount} {policy.currencyCode}
        </div>{' '}
        {/* annual premium column */}
        {/* cost center column */}
        <div />
        <div>
          <DeleteButton onDelete={(): void => onDelete(policy)} policy={policy} />{' '}
        </div>
        {/* delete button */}
      </div>
    );
  });
};

export const LoadingRow = (): JSX.Element => {
  return (
    <tia-table-row>
      <div className={styles.rowLoading}>
        <tia-spinner />
      </div>
    </tia-table-row>
  );
};

export const NoSelectedRow = (): JSX.Element => {
  const { t } = useTranslation();

  return <div className={styles.rowLoading}>{t('employeesTable.noMasterPolicySelected')}</div>;
};

export const NoEmployeesRow = (): JSX.Element => {
  const { t } = useTranslation();

  return <div className={styles.rowLoading}>{t('employeesTable.noPoliciesUnderMasterPolicy')}</div>;
};

export default PolicyRow;
