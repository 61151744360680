import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import { FullWidth } from '../../../styles/ViewGrid/ViewGrid.styled';
import { apiConnector } from '../../../utils/apiConnector';
import getHandleSubmit from '../../../utils/handleEntityFormSubmit';
import { getRouteToEmployeesViewParty } from '../../../routes';
import { useTranslation } from 'react-i18next';

const CONFIG_NAME = 'EditEmployee';

const EditEmployee: FC<RouteComponentProps> = (props) => {
  const urlParams = props.match.params as { partyId: string };
  const partyId = urlParams.partyId;
  const { t } = useTranslation();

  const rerouteOnSubmit = (pid: string): void => {
    props.history.push(getRouteToEmployeesViewParty(pid));
  };

  const handleSubmit = getHandleSubmit(
    CONFIG_NAME,
    apiConnector(),
    rerouteOnSubmit,
    false,
    t,
    partyId,
    t('employeeUpdated')
  );

  const handleCancel = (): void => {
    props.history.push(getRouteToEmployeesViewParty(partyId));
  };

  return (
    <ViewGrid>
      <FullWidth>
        <CustomUiWidget
          configName={CONFIG_NAME}
          partyId={partyId}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      </FullWidth>
    </ViewGrid>
  );
};

export default withRouter(EditEmployee);
