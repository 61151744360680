/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';

/**
 * Custom hook
 * Add eventlistener and bind it to a 'ref'
 * @param eventName
 * @param handler
 */
const useEventBinder = (eventName: string, handler: (arg?: any) => void): React.MutableRefObject<any> => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const element = ref.current as any;

      element.addEventListener(eventName, handler);

      return (): void => {
        element.removeEventListener(eventName, handler);
      };
    }
  });

  return ref;
};

export default useEventBinder;
