import { createContext } from 'react';

interface ITwoWayContext {
  message: boolean;
  sendMessage?: () => void;
}

export const twoWayContextDefaultState = {
  message: false,
};

export const TwoWayContext = createContext<ITwoWayContext>(twoWayContextDefaultState);
