import React, { FC, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import { FullWidth } from '../../../styles/ViewGrid/ViewGrid.styled';
import { apiConnector } from '../../../utils/apiConnector';
import getHandleSubmit from '../../../utils/handleEntityFormSubmit';
import { UserContext } from '../../../utils/UserContext';
import { useTranslation } from 'react-i18next';

const CONFIG_NAME = 'EditCorporate';
const getCancelPath = (history: History): string => {
  const pathArray = history.location.pathname.split('/');

  return `/${pathArray[pathArray.length - 2]}`;
};

const EditCorporation: FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const identityTypes = useContext(UserContext);
  const rerouteOnSubmit = (): void => {
    props.history.push(getCancelPath(props.history));
  };

  const handleSubmit = getHandleSubmit(
    CONFIG_NAME,
    apiConnector(),
    rerouteOnSubmit,
    false,
    t,
    identityTypes.corporatefirm.user_id,
    t('corporateUpdated')
  );

  const handleCancel = (): void => {
    props.history.push(getCancelPath(props.history));
  };

  return (
    <ViewGrid>
      <FullWidth>
        <CustomUiWidget
          configName={CONFIG_NAME}
          partyId={identityTypes.corporatefirm.user_id}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      </FullWidth>
    </ViewGrid>
  );
};

export default withRouter(EditCorporation);
