import React from 'react';
import { IUnauthenticated } from '@tia/authenticator/dist/components/AuthProvider';
import { Button } from '@tia/react-ui-library';
import { useTranslation } from 'react-i18next';
import { StyledCard, StyledCardActions, StyledCardContent, StyledCardHeader } from '../../styles/Card.styled';
import styled from 'styled-components';

export const Unauthenticated: React.FC<IUnauthenticated> = (props) => {
  const { errors, login } = props;
  const { t } = useTranslation();

  return (
    <UnauthenticatedWrapper>
      <StyledCard>
        <StyledCardHeader>{t('unauthenticated.title')}</StyledCardHeader>
        {!errors && <StyledCardContent>{t('unauthenticated.description')}</StyledCardContent>}
        {errors && (
          <div>
            <div>{t('unauthenticated.unexpectedError')}:</div>
            <StyledCardContent withError={true}>{errors}</StyledCardContent>
          </div>
        )}
        <StyledCardActions>
          <Button onClick={() => login({ prompt: 'login' })} block={true}>
            {t('unauthenticated.buttonSignIn')}
          </Button>
        </StyledCardActions>
      </StyledCard>
    </UnauthenticatedWrapper>
  );
};

export const UnauthenticatedWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--tia-body-bg, #fafbfd);
`;
