import { useState, useEffect } from 'react';
import PolicyStatus from '@tia/corporate-api-connector/dist/model/PolicyStatus';

/**
 * Custom hook
 * Extract policy status from query params if present
 * @param params
 */
const usePolicyStatus = (params: string): PolicyStatus => {
  const [derivedPolicyStatus, setDerivedPolicyStatus] = useState<PolicyStatus>(PolicyStatus.UNKNOWN);

  useEffect(() => {
    const formattedParams = new URLSearchParams(params);
    const policyStatus = formattedParams.get('policyStatus') as PolicyStatus;

    setDerivedPolicyStatus(policyStatus);
  }, [params]);

  return derivedPolicyStatus;
};

export default usePolicyStatus;
