import { RemoteResource } from './RemoteResource';
import React from 'react';

interface IProps {
  onLoad?: () => void;
}
export const RemoteCorporateWidgetsBundleLoader: React.FC<IProps> = (props) => {
  return (
    <RemoteResource
      url={getTiaCorporateWidgetsBundleSrc()}
      scriptIdentifier="tia.corporate.widgets.bundle.js"
      onLoad={props.onLoad}
      onError={() => console.error('Failed to load required resource')}
    />
  );
};

const getTiaCorporateWidgetsBundleSrc = (): string => {
  const src = process.env.REACT_APP_CORPORATE_WIDGETS_SRC;

  if (!src) {
    throw Error('env variable REACT_APP_CORPORATE_WIDGETS_SRC cannot be empty');
  }

  return src;
};
