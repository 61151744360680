const calculatePagination = (currentPage: number, numberOfPages: number): number[] => {
  if (!numberOfPages) {
    return [];
  }

  if (numberOfPages < 6) {
    return [...Array(numberOfPages)].map((_: undefined, nr: number) => nr + 1);
  }

  if (currentPage === 1 || currentPage === 2 || currentPage === numberOfPages - 1 || currentPage === numberOfPages) {
    return [1, 2, NaN, numberOfPages - 1, numberOfPages];
  }

  if (currentPage > 2 && currentPage < numberOfPages - 1) {
    return [1, NaN, currentPage, NaN, numberOfPages];
  }

  return [];
};

export default calculatePagination;
