import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import IPolicy from '@tia/corporate-api-connector/dist/model/IPolicy';
import PolicyListWidget from '../../../widget/PolicyListWidget/PolicyList.widget';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import {
  getRouteToEmployeesNewQuote,
  getRouteToEmployeesEditEmployee,
  getRouteToEmployeesViewPolicy,
} from '../../../routes';
import { getLanguage } from '../../../utils/language';

const EmployeeDetails: FC<RouteComponentProps> = (props) => {
  const urlParams = props.match.params as { partyId: string };
  const partyId = urlParams.partyId;

  const handlePolicyClick = (policyDetails: IPolicy): void => {
    props.history.push(getRouteToEmployeesViewPolicy(partyId, policyDetails.sipasUuid, policyDetails.policyStatus));
  };

  const handleNewQuoteClick = (): void => {
    props.history.push(getRouteToEmployeesNewQuote(partyId));
  };

  const handleEdit = (): void => {
    props.history.push(getRouteToEmployeesEditEmployee(partyId));
  };

  return (
    <ViewGrid>
      <CustomUiWidget configName={'EmployeeDetails'} partyId={partyId} handleEdit={handleEdit} />
      <PolicyListWidget
        showHeaderButton={true}
        policyStatus="All"
        partyId={partyId}
        onPolicyClick={handlePolicyClick}
        onNewQuoteClick={handleNewQuoteClick}
        underlyingPolicies={true}
        language={getLanguage()}
      />
    </ViewGrid>
  );
};

export default withRouter(EmployeeDetails);
