import { atomWithStorage } from 'jotai/utils';
import { TCP_ACCESS_TOKEN } from '../../hooks/useTwoWayLogin';

export const idTokenAtom = atomWithStorage('id_token', '');
export const accessTokenAtom = atomWithStorage('access_token', '');
export const agentAccessTokenAtom = atomWithStorage('tiaagent_access_token', '');
export const corporateEndpointAtom = atomWithStorage('corporate_endpoint', process.env.REACT_APP_API_URI);
export const spdSalesUiApiAtom = atomWithStorage('spd_sales_ui_api', process.env.REACT_APP_CORPORATE_API);
export const corporateAccessTokenAtom = atomWithStorage('tiacorporate_access_token', '');
export const tcpAccessTokenAtom = atomWithStorage(TCP_ACCESS_TOKEN, '');
export const tcpExpiresAtAtom = atomWithStorage('tia.widgets.tcp.expiresAt', '');
export const dateFormatAtom = atomWithStorage('dateFormat', '');
