import React, { useMemo } from 'react';
import { AuthProvider } from '@tia/authenticator';
import { User } from 'oidc-client';
import { Unauthenticated } from './Unauthenticated';
import { LoadingIndicator } from '../loadingIndicator/LoadingIndicator';
import { getUserIdentityTypes } from '../../utils/UserContext';
import { jotaiStore } from '../../utils/apiConnector';
import { RESET } from 'jotai/utils';
import {
  idTokenAtom,
  agentAccessTokenAtom,
  corporateAccessTokenAtom,
  tcpAccessTokenAtom,
  tcpExpiresAtAtom,
} from '../../utils/atoms/atoms';
import getSearchParams from '../../../src/utils/getSearchParams';

const AGENT_TOKEN = 'tiaagent_access_token';
const CORPORATE_TOKEN = 'tiacorporate_access_token';

const handleOnTokenRenew = (user: User) => {
  if (user) {
    const accessToken = user.access_token;
    const idToken = user.id_token;
    jotaiStore.set(idTokenAtom, idToken);
    jotaiStore.set(agentAccessTokenAtom, accessToken);
    jotaiStore.set(corporateAccessTokenAtom, accessToken);
  }
};

export const CorporateAuthProvider: React.FC = ({ children }) => {
  const authOptions = useMemo(getAuthOptions, []);

  return (
    <AuthProvider
      UnauthenticatedState={Unauthenticated}
      LoadingState={LoadingIndicator}
      issuer={authOptions.issuer}
      tokenEndpoint={authOptions.tokenEndpoint}
      authorizationEndpoint={authOptions.authorizationEndpoint}
      userinfoEndpoint={authOptions.userinfoEndpoint}
      logoutEndpoint={authOptions.logoutEndpoint}
      jwksUri={authOptions.jwksUri}
      revocationEndpoint={authOptions.revocationEndpoint}
      clientSecret={authOptions.clientSecret}
      logoutUri={authOptions.successfulLogoutUri}
      audience={authOptions.audience}
      clientId={authOptions.clientID}
      redirectUri={authOptions.redirectUri}
      onTokenRenew={handleOnTokenRenew}
      getSignInSilentParams={getSignInSilentParams}
      onLogout={() => {
        jotaiStore.set(idTokenAtom, RESET);
        jotaiStore.set(agentAccessTokenAtom, RESET);
        jotaiStore.set(corporateAccessTokenAtom, RESET);
        jotaiStore.set(tcpAccessTokenAtom, RESET);
        jotaiStore.set(tcpExpiresAtAtom, RESET);
      }}
      scope="openid profile offline_access"
    >
      {children}
    </AuthProvider>
  );
};

const getSignInSilentParams = (user: User) => {
  const identityTypes = getUserIdentityTypes(user);

  return {
    corporatefirm: identityTypes.corporatefirm.user_id,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAuthOptions = () => {
  const {
    issuer,
    clientID,
    audience,
    tokenEndpoint,
    authorizationEndpoint,
    userinfoEndpoint,
    logoutEndpoint,
    jwksUri,
    revocationEndpoint,
    clientSecret,
  } = window.corpEnv;

  if (!clientID) {
    throw Error('clientID configuration is missing');
  }

  const windowLocation = window.location.origin;
  const contextPath = window.corpEnv.contextPath || '';
  const contextLocation = contextPath ? `${windowLocation}/${contextPath}` : windowLocation;

  return {
    clientID,
    audience,
    issuer: `${issuer}`,
    tokenEndpoint: `${tokenEndpoint}`,
    authorizationEndpoint: `${authorizationEndpoint}`,
    userinfoEndpoint: `${userinfoEndpoint}`,
    logoutEndpoint: `${logoutEndpoint}`,
    jwksUri: `${jwksUri}`,
    revocationEndpoint: `${revocationEndpoint}`,
    clientSecret: `${clientSecret}`,
    redirectUri: `${contextLocation}/callback`,
    successfulLogoutUri: `${contextLocation}/logout`,
    responseType: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    tokenKey: [CORPORATE_TOKEN, AGENT_TOKEN],
  };
};
