import React, { FC, ReactElement } from 'react';
import {
  EntryTypes,
  IStatus,
  IColumnDefinition,
  ITableDefinition,
  ITableHeaderProps,
  ITableContentProps,
} from '../../interfaces/ITable.interface';
import styles from './table.module.scss';

const cellFromEntry = (entry: EntryTypes, key: number, cellType?: string): JSX.Element | null => {
  switch (cellType) {
    case 'status': {
      const { color, label } = entry as IStatus;

      return (
        <div key={key}>
          <tia-tag label={label} color={color} read-only="true" />
        </div>
      );
    }

    case 'string': {
      const entryString = entry as string;

      return <div key={key}>{entryString}</div>;
    }

    default: {
      console.error('This cell type doesnt exist');

      return null;
    }
  }
};

const TableHeader: FC<ITableHeaderProps> = (props) => {
  const getHeaderEntry = (string: string, key: number): JSX.Element => <div key={key}>{string}</div>;

  return <tia-table-header>{props.columnNames.map(getHeaderEntry)}</tia-table-header>;
};

const TableContent: FC<ITableContentProps> = (props) => {
  if (props.loading) {
    return (
      <tia-table-row>
        <div className={styles.rowLoading}>
          <tia-spinner />
        </div>
      </tia-table-row>
    );
  }

  return (props.data.map((dataEntry, key) =>
    rowFromData(dataEntry, props.columnDefinitions, key)
  ) as unknown) as ReactElement;
};

const rowFromData = <T extends { [key: string]: EntryTypes }>(
  dataEntry: T,
  columnDefinitions: IColumnDefinition[],
  key: number
): JSX.Element => {
  const getCell = (mapEntry: IColumnDefinition, entryKey: number): JSX.Element | null =>
    cellFromEntry(dataEntry[mapEntry.valueName], entryKey, mapEntry.cellType);

  return (
    <tia-table-row key={key}>
      <div>{columnDefinitions.map(getCell)}</div>
    </tia-table-row>
  );
};

type IProps = ITableDefinition<{ [key: string]: EntryTypes }>;

/**
 * Validates that the poperties passed to the Table component are
 * matching the basic assumptions of the component and of the Ui Library
 * used underneath. Returns an error message and empty string if no
 * problems were found.
 * @param props all properties as expected by the Table component
 */
const validateTableProps = (props: IProps): string => {
  const { data, columnNames, columnDefinitions, widths } = props;

  if (data === undefined || data.length < 1) {
    return '';
  }

  if (
    ![Object.keys(data[0]), columnNames, columnDefinitions, widths].every((v, i, arr) => v.length === arr[0].length)
  ) {
    return 'Number of data entries, column names, column definitions and widths has to be equal';
  }

  return '';
};

const Table = (props: IProps): JSX.Element | null => {
  const propsValidationError = validateTableProps(props);

  if (propsValidationError) {
    console.error(propsValidationError);

    return null;
  }

  const { title, data, columnNames, columnDefinitions, widths, loading } = props;

  return (
    <tia-panel title={title} width="100%">
      <tia-table widths={`[${widths.join(', ')}]`}>
        <TableHeader columnNames={columnNames} />
        <TableContent data={data} columnDefinitions={columnDefinitions} loading={loading} />
      </tia-table>
    </tia-panel>
  );
};

export default Table;
