import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { apiConnector } from '../../utils/apiConnector';
import { UserContext } from '../../utils/UserContext';
import IPolicy from '@tia/corporate-api-connector/dist/model/IPolicy';
import useEventBinder from '../../hooks/useEventBinder';
import styles from './employeesSection.module.scss';

interface IProps extends RouteComponentProps {
  routeCreateEmployeeHandler: () => void;

  selectHandler(value: string): void;

  search(value: string): void;

  selectedPartyId: string;
}

const PanelHeader: FC<IProps> = (props) => {
  const newEmployeeButton = useRef<HTMLElement>();
  const searchField = useRef<HTMLInputElement>();
  const [data, setData] = useState([] as IPolicy[]);
  const [selectedMp, setSelectedMp] = useState<number>();
  const identityType = useContext(UserContext);
  const { t } = useTranslation();

  const selectMasterPolicyRef = useEventBinder('change', (e) => {
    props.selectHandler(e.detail.value);
    setSelectedMp(e.detail.value);
  });

  const fetchData = async (): Promise<void> => {
    try {
      const corporatePolicies = await apiConnector().policy.getPolicies(
        'Policies',
        identityType.corporatefirm.user_id,
        true
      );

      if (corporatePolicies.data && corporatePolicies.data.length > 0) {
        setData(corporatePolicies.data);
        props.selectHandler(corporatePolicies.data[0].policyNo[0].toString());
      }
    } catch (e) {
      console.error('Could not fetch master', e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setSelectedMp(data[0].policyNo[0]);
    }

    if (newEmployeeButton.current) {
      newEmployeeButton.current.addEventListener('click', props.routeCreateEmployeeHandler, false);
    }

    if (searchField.current) {
      searchField.current.addEventListener('change', searchInputChanged, false);
    }

    return (): void => {
      newEmployeeButton.current?.removeEventListener('click', props.routeCreateEmployeeHandler);
      searchField.current?.removeEventListener('change', searchInputChanged);
    };
  });

  const searchInputChanged = (event: Event): void => {
    props.search((event as CustomEvent).detail.value);
  };

  const options = data.map((policy) => (
    <tia-option key={policy.sipasUuid} value={policy.policyNo[0]}>
      {policy.sipasProdName} - {policy.policyNo[0]}
    </tia-option>
  ));

  if (!options.length) {
    return null;
  }

  return (
    <div slot="header">
      <div className={styles.employeesHeader}>
        <div className={styles.employeesHeaderWrapperSelect}>
          <tia-select
            ref={selectMasterPolicyRef}
            placeholder={t('employeesTable.dropdownPlaceholder')}
            helptext=""
            error=""
            size="small"
            theme="default"
            full="true"
            value={selectedMp}
          >
            {options}
          </tia-select>
        </div>
        <div className={styles.employeesHeaderButtonWrapper}>
          <tia-button
            ref={newEmployeeButton}
            name={t('employeesTable.newEmployeeButton')}
            icon="add"
            type="primary"
            size="small"
            shrink="false"
            disabled="false"
            full="false"
            loading="false"
          />
        </div>
      </div>
    </div>
  );
};

export default PanelHeader;
