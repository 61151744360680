import styles from './rolesTable.module.scss';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import IPage from '@tia/corporate-api-connector/dist/model/IPage';
import { apiConnector } from '../../utils/apiConnector';
import IUserRelation from '@tia/corporate-api-connector/dist/model/IUserRelation';
import TablePagination from '../tablePagination/TablePagination.component';
import Table from '../table/Table.component';
import { IRoleDataEntry, IStatus } from '../../interfaces/ITable.interface';
import { convertISOToDateString } from '../../utils/dateFormatter';

const rolesTableColumnDefinitions = [
  {
    valueName: 'id',
    cellType: 'string',
  },
  {
    valueName: 'name',
    cellType: 'string',
  },
  {
    valueName: 'email',
    cellType: 'string',
  },
  {
    valueName: 'startDate',
    cellType: 'string',
  },
  {
    valueName: 'endDate',
    cellType: 'string',
  },
  {
    valueName: 'relationStatus',
    cellType: 'status',
  },
];

const getRolesTableData = (userRelations: IUserRelation[]): IRoleDataEntry[] => {
  if (!userRelations) {
    return [];
  }

  return userRelations.map((userRelation) => ({
    email: userRelation.contactDetail,
    endDate: convertISOToDateString(userRelation.endDate),
    startDate: convertISOToDateString(userRelation.startDate),
    id: String(userRelation.partyId),
    name: userRelation.partyName,
    relationStatus: getRelationStatus(userRelation.startDate, userRelation.endDate),
  }));
};

const getRelationStatus = (startDate: string, endDate: string): IStatus => {
  if (!endDate) {
    return {
      color: 'green',
      label: 'active',
    };
  }

  const endDateObject = new Date(Date.parse(endDate));
  const startDateObject = new Date(Date.parse(startDate));
  const today = new Date();

  if (endDateObject < today || startDateObject > today) {
    return {
      color: 'red',
      label: 'inactive',
    };
  }

  return {
    color: 'green',
    label: 'active',
  };
};

const defaultPaginationInfo: IPage = {
  totalPages: 1,
  number: 0,
  numberOfElements: 0,
  first: true,
  last: true,
  size: 0,
  totalElements: 0,
};

const RolesTable: FC = () => {
  const [paginationInfo, setPaginationInfo] = useState<IPage>(defaultPaginationInfo);
  const [data, setData] = useState([] as IUserRelation[]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const rolesTableColumnNames = [
    t('rolesTableHeader.id'),
    t('rolesTableHeader.name'),
    t('rolesTableHeader.email'),
    t('rolesTableHeader.startDate'),
    t('rolesTableHeader.endDate'),
    t('rolesTableHeader.status'),
  ];

  const handlePageChange = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  const fetchData = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const userRelationsResponse = await apiConnector().user.getCorporateFirmUsers({
        page: currentPage.toString(),
        size: '10',
      });

      if (!userRelationsResponse) {
        throw Error('Result is not a valid object');
      }

      setData(userRelationsResponse.data);

      if (userRelationsResponse.page) {
        setPaginationInfo(userRelationsResponse.page);
      }
    } catch (e) {
      console.error('Could not fetch users', e);
      setLoading(false);
    }

    setLoading(false);
  }, [currentPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.sectionContainer}>
      <Table
        title={t('roles')}
        data={getRolesTableData(data)}
        columnNames={rolesTableColumnNames}
        columnDefinitions={rolesTableColumnDefinitions}
        widths={[10, 15, 28, 18, 18, 11]}
        loading={loading}
      />
      <TablePagination
        paginationInfo={paginationInfo}
        onPageChange={handlePageChange}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default RolesTable;
