import React from 'react';
import { Spinner } from '@tia/react-ui-library';
import styled from 'styled-components';

export const LoadingIndicator: React.FC = (props) => {
  return (
    <AuthContainer>
      <Spinner size={30} />
      <ContentContainer>{props.children}</ContentContainer>
    </AuthContainer>
  );
};

const ContentContainer = styled.span`
  padding-left: 0.5rem;
`;

const AuthContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tia-body-bg, #fafbfd);
`;
