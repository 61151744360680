import React from 'react';
import MenuItem from '../menuItem/MenuItem.component';
import styles from './menu.module.scss';

interface IProps {
  isExpanded: boolean;
  toggleExpanded: () => void;
  children: Array<React.ReactElement<typeof MenuItem>> | React.ReactElement<typeof MenuItem>;
}

const Menu: React.FunctionComponent<IProps> = (props): JSX.Element => {
  const style = props.isExpanded ? { width: 240 } : { width: 104 };
  const toggleIcon = props.isExpanded ? 'chevron_left' : 'chevron_right';

  return (
    <div className={styles.menuContainer} style={style}>
      {props.children}
      <div className={styles.expandButton} onClick={props.toggleExpanded}>
        <tia-icon name={toggleIcon} color={'#929AA8'} />
      </div>
    </div>
  );
};

export default Menu;
