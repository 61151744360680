import React, { useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getRouteToEmployeesNewEmployee, getRouteToEmployeesEditCorporation } from '../../routes';
import CustomUiWidget from '../../widget/CustomUiWidget/CustomUi.widget';
import ViewGrid from '../../styles/ViewGrid/ViewGrid';
import { UserContext } from '../../utils/UserContext';
import EmployeesSection from '../../components/employeesSection/EmployeesSection.component';

const Employees: React.FC<RouteComponentProps> = (props) => {
  const identityTypes = useContext(UserContext);

  const handleCreateEmployeeRouting = (): void => {
    props.history.push(getRouteToEmployeesNewEmployee());
  };

  const handleEditEvent = (): void => {
    props.history.push(getRouteToEmployeesEditCorporation());
  };

  return (
    <ViewGrid>
      <CustomUiWidget
        configName={'CorporateDetails'}
        partyId={identityTypes.corporatefirm.user_id}
        handleEdit={handleEditEvent}
      />
      <EmployeesSection routeCreateEmployeeHandler={handleCreateEmployeeRouting} />
    </ViewGrid>
  );
};

export default withRouter(Employees);
