/**
 * convert YYYY-MM-DDThh:mm:ssZ to dd-MM-YYYY
 * @param date ISO8601 string
 */
export const convertISOToDateString = (date: Date | string | null | undefined): string => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString('nl', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
